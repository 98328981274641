
import { defineComponent, reactive, ref, toRefs } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import { getContractOwnerByTimeRange } from "@/core/services/api/contractOwner";
import { getMembersBySpace } from "@/core/services/api/members";
import {
  filterTableDataBySearch,
  filterTableDataByBeginEnd,
  BeginEndModel,
} from "@/core/utils/tableSearch";
import moment from "moment";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import SetUser from "./components/SetUser.vue";
import EditInfo from "./components/EditInfo.vue";

export default defineComponent({
  components: {
    Datatable,
    SetUser,
    EditInfo
  },
  setup() {
    // 空間唯一識別
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    // 當前項目
    const currentItem = reactive({});
    // 空間會員
    const members: any = reactive([]);
    // 列表資料
    const tableData: any = reactive([]);
    // 初始列表資料
    const initTableData: any = reactive([]);
    // 列表標題
    const tableHeader = ref([
      { key: "owner", name: "合約擁有者", sortable: true },
      { key: "contractName", name: "合約範本名稱", sortable: true },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "userManagementPermission", name: "合約管理者", sortable: true },
      { key: "createTime", name: "創建時間", sortable: true },
      { key: "remark", name: "備註", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    // 合約管理者選單
    const contractManagerOptions = {
      Owner: "合約擁有者",
      SpaceManager: "space Go 管理員",
    };

    const timePickerData: any = reactive({
      value: [
        moment()
          .subtract(2, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    // 列表加載中
    const isTableLoadind = ref<boolean>(false);
    // 搜尋輸入值
    const search = ref<string>("");
    // 時間過濾
    let timeFilter = {
      beginTime: timePickerData.value[0],
      endTime: timePickerData.value[1],
    };

    // 根據時間範圍取得空間合約優惠
    const handleGetContractOwnerByTimeRange = async () => {
      let request = {
        beginTime: timeFilter.beginTime,
        endTime: timeFilter.endTime,
      };
      return await getContractOwnerByTimeRange(currentSpaceGuid!, request);
    };

    // 設置列表資料
    const setTableData = async () => {
      let contractOwners: any = await handleGetContractOwnerByTimeRange();
      console.log("contractOwners", contractOwners);
      tableData.splice(0);
      for (const contractOwner of contractOwners) {
        tableData.push({
          data:contractOwner,
          contractOwnerGuid: contractOwner.guid,
          contractGuid: contractOwner.contract.guid,
          owner: getMemberNameByGuid(contractOwner.owner),
          ownerGuid: contractOwner.owner,
          contractName: contractOwner.contract.name,
          beginTime: getLocalTimeString(contractOwner.beginTime),
          endTime: getLocalTimeString(contractOwner.endTime),
          permission: contractOwner.userManagementPermission,
          userManagementPermission:
            contractManagerOptions[contractOwner.userManagementPermission],
          createTime: getLocalTimeString(contractOwner.createTime),
          remark:contractOwner.remark
        });
      }
      tableData.sort(function (a, b) {
        return moment(b.createTime).diff(moment(a.createTime));
      });
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    const updateTime = async (time) => {
      timeFilter.beginTime = getUtcTimeString(time[0]);
      timeFilter.endTime = getUtcTimeString(time[1]);
      await setTableData();
    };

    // 取得空間使用者
    const getSpaceMembers = async () => {
      return await getMembersBySpace(currentSpaceGuid!);
    };

    // 根據guid取得會員名稱
    const getMemberNameByGuid = (guid) => {
      let filterMember = members.filter((member) => member.guid == guid);
      return filterMember[0].name;
    };

    // 設置當前項目
    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    // 初始化
    const init = async () => {
      members.splice(0, members.length, ...(await getSpaceMembers()));
      await setTableData();
    };
    init();

    return {
      ...toRefs(timePickerData),
      tableData,
      tableHeader,
      isTableLoadind,
      search,
      handleFilterTableDataBySearch,
      updateTime,
      setCurrentItem,
      currentItem,
      init
    };
  },
});
