<template>
  <el-form
    label-position="left"
    @submit.prevent="onSubmit()"
    :model="formData"
    :rules="rules"
    ref="formRef"
    class="form"
    label-width="110px"
  >
    <el-form-item label="起訖時間" prop="name">
      <el-date-picker
        v-model="formData.timeRange"
        type="datetimerange"
        start-placeholder="開始日期"
        end-placeholder="結束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="合約管理者">
      <el-select
        v-model="formData.userManagementPermission"
        filterable
        placeholder="請選擇合約管理者"
        style="width: 100%"
      >
        <el-option
          v-for="item in contractManagerOptions"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="備註">
      <el-input v-model="formData.remark" placeholder="請輸入備註" />
    </el-form-item>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { modifyContractOwner } from "@/core/services/api/contractOwner";
import localstorage from "@/core/services/LocalstorageService";
import {getUtcTimeString} from "@/core/utils/timeString"
import moment from "moment";

export default defineComponent({
  props: ["data"],
  setup(props, { emit }) {
    const formData:any = reactive({
      timeRange: [],
      userManagementPermission: "",
      remark: "",
    });
    const rules = {
      timeRange: [
        { required: true, message: "請填選起訖時間", trigger: "blur" },
      ],
    };
    const formRef = ref<null | HTMLFormElement>(null);
    // 合約管理者選單
    const contractManagerOptions = reactive([
      { text: "合約擁有者", value: "Owner" },
      { text: "space Go 管理員", value: "SpaceManager" },
    ]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要修改嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                beginTime: getUtcTimeString(formData.timeRange[0]),
                endTime: getUtcTimeString(formData.timeRange[1]),
                userManagementPermission: formData.userManagementPermission,
                remark: formData.remark,
              };
              await modifyContractOwner(
                currentSpaceGuid,
                props.data.data.contract.guid,
                props.data.data.guid,
                request
              ).then(async () => {
                await Swal.fire("修改成功!", "", "success");
                emit("onModify");
              });
            }
          });
        }
      });
    };

    const setFormData = () => {
      Object.assign(formData, {
        timeRange: [props.data.data.beginTime, props.data.data.endTime],
        userManagementPermission: props.data.data.userManagementPermission,
        remark: props.data.data.remark,
      });
    //   formData.value = {
    //     timeRange: [props.data.data.beginTime, props.data.data.endTime],
    //     userManagementPermission: props.data.data.userManagementPermission,
    //     remark: props.data.data.remark,
    //   }
      console.log("formData",formData)
    };

    watch(
      props.data,
      () => {
        console.log("EditInfo props data",props.data)
        setFormData();
      },
      {
        // immediate: true,
        deep: true,
      }
    );

    return {
      formData,
      rules,
      onSubmit,
      formRef,
      contractManagerOptions,
    };
  },
});
</script>

<style>
</style>