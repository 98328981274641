
import { defineComponent, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { modifyContractOwner } from "@/core/services/api/contractOwner";
import localstorage from "@/core/services/LocalstorageService";
import {getUtcTimeString} from "@/core/utils/timeString"
import moment from "moment";

export default defineComponent({
  props: ["data"],
  setup(props, { emit }) {
    const formData:any = reactive({
      timeRange: [],
      userManagementPermission: "",
      remark: "",
    });
    const rules = {
      timeRange: [
        { required: true, message: "請填選起訖時間", trigger: "blur" },
      ],
    };
    const formRef = ref<null | HTMLFormElement>(null);
    // 合約管理者選單
    const contractManagerOptions = reactive([
      { text: "合約擁有者", value: "Owner" },
      { text: "space Go 管理員", value: "SpaceManager" },
    ]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要修改嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                beginTime: getUtcTimeString(formData.timeRange[0]),
                endTime: getUtcTimeString(formData.timeRange[1]),
                userManagementPermission: formData.userManagementPermission,
                remark: formData.remark,
              };
              await modifyContractOwner(
                currentSpaceGuid,
                props.data.data.contract.guid,
                props.data.data.guid,
                request
              ).then(async () => {
                await Swal.fire("修改成功!", "", "success");
                emit("onModify");
              });
            }
          });
        }
      });
    };

    const setFormData = () => {
      Object.assign(formData, {
        timeRange: [props.data.data.beginTime, props.data.data.endTime],
        userManagementPermission: props.data.data.userManagementPermission,
        remark: props.data.data.remark,
      });
    //   formData.value = {
    //     timeRange: [props.data.data.beginTime, props.data.data.endTime],
    //     userManagementPermission: props.data.data.userManagementPermission,
    //     remark: props.data.data.remark,
    //   }
      console.log("formData",formData)
    };

    watch(
      props.data,
      () => {
        console.log("EditInfo props data",props.data)
        setFormData();
      },
      {
        // immediate: true,
        deep: true,
      }
    );

    return {
      formData,
      rules,
      onSubmit,
      formRef,
      contractManagerOptions,
    };
  },
});
