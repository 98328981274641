<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="handleFilterTableDataBySearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col-lg col-12 d-flex align-items-center overflow-auto">
          <el-date-picker
            class="ms-xxl-5 ms-xl-5 ms-0"
            v-model="value"
            type="datetimerange"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            @change="updateTime"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div v-if="isTableLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-owner="{ row: tableData }">
          {{ tableData.owner }}
        </template>
        <template v-slot:cell-contractName="{ row: tableData }">
          {{ tableData.contractName }}
        </template>
        <template v-slot:cell-beginTime="{ row: tableData }">
          {{ tableData.beginTime }}
        </template>
        <template v-slot:cell-endTime="{ row: tableData }">
          {{ tableData.endTime }}
        </template>
        <template v-slot:cell-userManagementPermission="{ row: tableData }">
          {{ tableData.userManagementPermission }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
        <template v-slot:cell-remark="{ row: tableData }">
          {{ tableData.remark }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentItem(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#setUser-modal"
                  >設定使用者</el-dropdown-item
                >
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#editInfo-modal"
                  >編輯資料</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 設定使用者modal -->
  <div
    class="modal fade"
    id="setUser-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="setUser-exampleModalLabel">設定使用者</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SetUser :contractOffer="currentItem"></SetUser>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯資料modal -->
  <div
    class="modal fade"
    id="editInfo-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="setUser-exampleModalLabel">編輯資料</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <EditInfo :data="currentItem" @onModify="init()"></EditInfo>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, toRefs } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import { getContractOwnerByTimeRange } from "@/core/services/api/contractOwner";
import { getMembersBySpace } from "@/core/services/api/members";
import {
  filterTableDataBySearch,
  filterTableDataByBeginEnd,
  BeginEndModel,
} from "@/core/utils/tableSearch";
import moment from "moment";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import SetUser from "./components/SetUser.vue";
import EditInfo from "./components/EditInfo.vue";

export default defineComponent({
  components: {
    Datatable,
    SetUser,
    EditInfo
  },
  setup() {
    // 空間唯一識別
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    // 當前項目
    const currentItem = reactive({});
    // 空間會員
    const members: any = reactive([]);
    // 列表資料
    const tableData: any = reactive([]);
    // 初始列表資料
    const initTableData: any = reactive([]);
    // 列表標題
    const tableHeader = ref([
      { key: "owner", name: "合約擁有者", sortable: true },
      { key: "contractName", name: "合約範本名稱", sortable: true },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "userManagementPermission", name: "合約管理者", sortable: true },
      { key: "createTime", name: "創建時間", sortable: true },
      { key: "remark", name: "備註", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    // 合約管理者選單
    const contractManagerOptions = {
      Owner: "合約擁有者",
      SpaceManager: "space Go 管理員",
    };

    const timePickerData: any = reactive({
      value: [
        moment()
          .subtract(2, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    // 列表加載中
    const isTableLoadind = ref<boolean>(false);
    // 搜尋輸入值
    const search = ref<string>("");
    // 時間過濾
    let timeFilter = {
      beginTime: timePickerData.value[0],
      endTime: timePickerData.value[1],
    };

    // 根據時間範圍取得空間合約優惠
    const handleGetContractOwnerByTimeRange = async () => {
      let request = {
        beginTime: timeFilter.beginTime,
        endTime: timeFilter.endTime,
      };
      return await getContractOwnerByTimeRange(currentSpaceGuid!, request);
    };

    // 設置列表資料
    const setTableData = async () => {
      let contractOwners: any = await handleGetContractOwnerByTimeRange();
      console.log("contractOwners", contractOwners);
      tableData.splice(0);
      for (const contractOwner of contractOwners) {
        tableData.push({
          data:contractOwner,
          contractOwnerGuid: contractOwner.guid,
          contractGuid: contractOwner.contract.guid,
          owner: getMemberNameByGuid(contractOwner.owner),
          ownerGuid: contractOwner.owner,
          contractName: contractOwner.contract.name,
          beginTime: getLocalTimeString(contractOwner.beginTime),
          endTime: getLocalTimeString(contractOwner.endTime),
          permission: contractOwner.userManagementPermission,
          userManagementPermission:
            contractManagerOptions[contractOwner.userManagementPermission],
          createTime: getLocalTimeString(contractOwner.createTime),
          remark:contractOwner.remark
        });
      }
      tableData.sort(function (a, b) {
        return moment(b.createTime).diff(moment(a.createTime));
      });
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    const updateTime = async (time) => {
      timeFilter.beginTime = getUtcTimeString(time[0]);
      timeFilter.endTime = getUtcTimeString(time[1]);
      await setTableData();
    };

    // 取得空間使用者
    const getSpaceMembers = async () => {
      return await getMembersBySpace(currentSpaceGuid!);
    };

    // 根據guid取得會員名稱
    const getMemberNameByGuid = (guid) => {
      let filterMember = members.filter((member) => member.guid == guid);
      return filterMember[0].name;
    };

    // 設置當前項目
    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    // 初始化
    const init = async () => {
      members.splice(0, members.length, ...(await getSpaceMembers()));
      await setTableData();
    };
    init();

    return {
      ...toRefs(timePickerData),
      tableData,
      tableHeader,
      isTableLoadind,
      search,
      handleFilterTableDataBySearch,
      updateTime,
      setCurrentItem,
      currentItem,
      init
    };
  },
});
</script>

<style>
</style>