
import { defineComponent, reactive, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {
    getContractUser,
    addContractUser,
} from "@/core/services/api/contractUser";
import localstorage from "@/core/services/LocalstorageService";
import { getMembersBySpace } from "@/core/services/api/members";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getSimpleUsers } from "@/core/services/api/members";

export default defineComponent({
    components: {
        Datatable,
    },
    props: ["contractOffer"],
    setup(props) {
        const addUserForm = ref("");
        // 空間唯一識別
        const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
        const permission = ref("");
        const tableData: any = reactive([]);
        const tableHeader = ref([
            { key: "user", name: "合約使用者" },
            { key: "email", name: "電子郵件" },
            { key: "createTime", name: "加入時間" },
            { key: "status", name: "狀態" },
        ]);
        const userOptions: any = reactive([]);
        // users
        const users: any = reactive([]);
        // 合約使用者列表
        const contractUsers: any = reactive([]);

        // 取得合約使用者
        const handleGetContractUser = async () => {
            return await getContractUser(
                currentSpaceGuid!,
                props.contractOffer.contractGuid,
                props.contractOffer.contractOwnerGuid
            );
        };

        // 根據guid取得會員
        const getUserByGuid = (guid) => {
            let filterMember = users.filter(
                (member) => member.simpleUser.guid == guid
            );

            if (filterMember.length == 0) {
                return {
                    name: guid,
                    email: "",
                };
            } else {
                return filterMember[0].simpleUser;
            }
        };

        // 設置列表資料
        const setTableData = async () => {
            tableData.splice(0);
            for (const contractUser of contractUsers) {
                const member = getUserByGuid(contractUser.user);
                tableData.push({
                    user: member.name,
                    email: member.email,
                    createTime: getLocalTimeString(contractUser.createTime),
                    status: contractUser.status,
                });
            }
        };

        const setUserOptions = async () => {
            let members = await getMembersBySpace(currentSpaceGuid!);
            userOptions.splice(0);
            members.forEach((o) => {
                userOptions.push({
                    text: `${o.name} (${o.email})`,
                    value: o.guid,
                });
            });
        };

        // 是否可添加使用者
        const isCanAddUser = () => {
            let currentUserGuid = JwtService.getToken();
            if (
                currentUserGuid == props.contractOffer.ownerGuid ||
                props.contractOffer.permission == "SpaceManager"
            ) {
                return true;
            }
            return false;
        };

        // 添加使用者
        const addUser = async () => {
            if (addUserForm.value != "") {
                await Swal.fire({
                    title: `您確定要新增 "${
                        getUserByGuid(addUserForm.value).name
                    }" 嗎?`,
                    confirmButtonText: "確定",
                    denyButtonText: `取消`,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await addContractUser(
                            currentSpaceGuid!,
                            props.contractOffer.contractGuid,
                            props.contractOffer.contractOwnerGuid,
                            addUserForm.value
                        );
                        await setTableData();
                        await Swal.fire("新增成功!", "", "success");
                    }
                });
            }
        };

        // 設置所有合約使用者
        const setContractUsers = async () => {
            contractUsers.splice(
                0,
                contractUsers.length,
                ...(await handleGetContractUser())
            );
        };

        // 設置所有user
        const setUsers = async () => {
            let userGuids = contractUsers.map((o) => o.user);
            if (userGuids.length !== 0) {
                const request = {
                    users: userGuids,
                };
                users.splice(
                    0,
                    users.length,
                    ...(await getSimpleUsers(request))
                );
            } else {
                users.splice(0);
            }
        };

        watch(
            props,
            async () => {
                await setContractUsers();
                await setUsers();
                await setTableData();
            },
            { deep: true }
        );

        // 初始化
        const init = async () => {
            await setUserOptions();
        };
        init();
const     contractUserStatusMap = {
      Enable: "啟用",
      Disable: "停用",
    };

        return {
            tableData,
            tableHeader,
            userOptions,
            addUserForm,
            permission,
            isCanAddUser,
            addUser,
            contractUserStatusMap
        };
    },
});
